import React from "react";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import CLOSE from "../../assets/images/icons/close.svg";
import ARROW from "../../assets/images/icons/arrow-down.svg";
import {  getUserFromCookies } from "../../utils/Auth";

export function CreateBusiness() {
  let [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const user = getUserFromCookies();

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function findKeyWithBusinessRole(obj) {
    for (const key in obj) {
      if (
        obj.hasOwnProperty(key) &&
        Array.isArray(obj[key].roles) &&
        obj[key].roles.includes("business")
      ) {
        return key;
      }
    }
    return null;
  }

  const isBusinessId = findKeyWithBusinessRole(user?.team_roles);

  function goToRestaurantPage() {
    //if user is a first time business go to onboarding restaurant
    if (isBusinessId === null) {
      navigate("/create-business/restaurant");
    } else {
      navigate("/create-restaurant");
    }
  }
  function goToStorePage() {
    //if user is a first time business go to onboarding store
    if (isBusinessId === null) {
      navigate("/create-business/store");
    } else {
      navigate("/create-store");
    }
  }
  return (
    <div data-test="createBusiness">
      <button className="flex items-center" onClick={openModal}>
        <p className="text-[#333333] font-normal hover:text-gray-700 text-sm sm:text-lg">
          Create Business
        </p>
        <img src={ARROW} alt="arrow-down" className="ml-2 w-3 h-2 " />
      </button>

      <Transition show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative sm:w-[550px] bg-white rounded-3xl px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:my-10 sm:pb-6 w-full ">
                  <div className="sm:block absolute top-1 sm:top-3 right-4 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-[#ffeae5] rounded-md p-2 sm:p-3 "
                      onClick={closeModal}
                    >
                      <span className="sr-only">Close</span>
                      <img
                        src={CLOSE}
                        alt="close"
                        className=" w-3 h-3 sm:w-4 sm:h-4"
                      />
                    </button>
                  </div>
                  <div className="sm:flex ">
                    <div className=" text-center mx-auto justify-center mb-2 mt-3">
                      <Dialog.Title
                        as="h3"
                        className=" text-2xl font-bold text-black"
                      >
                        Create Business
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="mt-3 ">
                    <div className="mt-4 border-b-2 "></div>
                    <div className="mt-6 ">
                      <div
                        onClick={goToRestaurantPage}
                        className="bg-white hover:bg-primary hover:bg-opacity-10 cursor-pointer rounded-2xl text-left hover:text-primary py-4 px-5 "
                      >
                        <h3 className="text-xl font-bold mb-1">
                          Add Restaurant Details
                        </h3>
                        <p className="text-md font-normal">
                          Create multiple restaurant outlets in Ogwugo to enjoy
                          more visibility and sales.
                        </p>
                      </div>
                      <div
                        onClick={goToStorePage}
                        className="bg-white hover:bg-primary hover:bg-opacity-10 cursor-pointer rounded-2xl text-left hover:text-primary py-4 px-5 mb-10  "
                      >
                        <h3 className="text-xl font-bold mb-1">
                          Add Store Details
                        </h3>
                        <p className="text-md font-normal">
                          Create multiple store outlets in Ogwugo to enjoy more
                          visibility and sales.
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
