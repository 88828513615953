import React, { useState, useEffect } from "react";
import { Popover } from "@headlessui/react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
//images and icons
import ARROW from "../../assets/images/icons/arrow-down.svg";
import Location from "../../assets/images/icons/Location.svg";
import { AddressModal } from "../modals/address/addressModal";
import { CreateBusiness } from "../modals/createBusinessModal";
import { SelectLocation } from "../modals/selectLocationModal";
import { isAuthenticatedUser } from "../../utils/Auth";

const tabs = [
  { name: "Home", href: "home" },
  { name: "Restaurants", href: "restaurants" },
  { name: "Stores", href: "stores" },
  { name: "Food Packages", href: "food-packages" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function User() {
  const DEFAULT_ACTIVE_TAB = "home";
  const params = useParams();
  const navigate = useNavigate();
  const isLoggedIn = isAuthenticatedUser();

  const [isOpen, setIsOpen] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);

  const { defaultAddress } = useSelector((state) => state.address);
  const user_city = localStorage.getItem("ogwugo-user-city");

  useEffect(() => {
    if (!params["*"]) {
      navigate(`/user/${DEFAULT_ACTIVE_TAB}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = (tab) => {
    if (params["*"] !== tab) {
      navigate(`/user/${tab.href}`);
    }
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function openLocationModal() {
    setOpenLocation(true);
  }

  // const getLocation = () => {
  //   console.log("navigator", navigator);
  //   if ("geolocation" in navigator) {
  //     // Geolocation is available
  //     console.log("yes");
  //     navigator.geolocation.getCurrentPosition(function (position) {
  //       var latitude = position.coords.latitude;
  //       var longitude = position.coords.longitude;
  //       console.log("Latitude: " + latitude + ", Longitude: " + longitude);
  //       // You can do whatever you want with the latitude and longitude here
  //     });
  //   } else {
  //     // Geolocation is not available
  //     console.log("Geolocation is not supported by this browser.");
  //   }
  // };

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  function successFunction(position) {
    var lat = position.coords.latitude;
    var lng = position.coords.longitude;
    reverseGeocode(lat, lng);
  }

  function errorFunction(error) {
    alert("Geocoder failed due to: " + error.message);
  }
  function reverseGeocode(lat, lng) {
    var xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      "https://nominatim.openstreetmap.org/reverse?format=json&lat=" +
        lat +
        "&lon=" +
        lng,
      true
    );
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var response = JSON.parse(xhr.responseText);
        var city =
          response.address.city ||
          response.address.town ||
          response.address.village ||
          response.address.county;

        localStorage.setItem("ogwugo-user-city", city);
        window.location.reload();
      }
    };
    xhr.send();
  }

  return (
    <div data-test="userNavBarComponent">
      {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
      <Popover
        as="header"
        className={({ open }) =>
          classNames(
            open ? "fixed inset-0 z-40 overflow-y-auto" : "",
            "bg-white shadow-sm lg:static lg:overflow-y-visible"
          )
        }
      >
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8">
                <div className="flex lg:static xl:col-span-4">
                  <div className="block py-2 sm:py-3 flex-shrink-0 sm:flex items-center">
                    <label className="text-[#676565] text-sm sm:text-base sm:text-md mr-1">
                      Delivering to:
                    </label>
                    {isLoggedIn ? (
                      <>
                        <div className="flex gap-1">
                          <p
                            className="text-sm sm:text-base font-medium cursor-pointer max-w-[120px] sm:max-w-[200px] truncate text-ellipsis overflow-hidden"
                            onClick={openModal}
                          >
                            {defaultAddress &&
                            Object?.keys(defaultAddress)?.length !== 0
                              ? defaultAddress?.address
                              : "Choose an address"}
                          </p>
                          <img
                            src={ARROW}
                            alt="arrow-down"
                            className="cursor-pointer"
                            onClick={openModal}
                          />
                        </div>
                        <AddressModal show={isOpen} onClose={closeModal} />
                      </>
                    ) : (
                      <div className="flex">
                        <p>Log In to add your delivery address</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6">
                  <div className="flex items-center px-6 py-3 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
                    <div className="hidden lg:block">
                      <nav
                        className="flex space-x-4 overflow-x-auto"
                        aria-label="Tabs"
                      >
                        {tabs.map((tab) => (
                          <button
                            key={tab.name}
                            onClick={() => {
                              toggle(tab);
                            }}
                            className={classNames(
                              tab.href === params["*"]
                                ? "bg-primary text-white"
                                : "text-[#333333] hover:text-gray-700",
                              "px-4 py-2 font-normal text-sm sm:text-lg rounded-md"
                            )}
                            aria-current={
                              tab.href === params["*"] ? "page" : undefined
                            }
                          >
                            {tab.name}
                          </button>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>
                {isLoggedIn && (
                  <div className="xl:col-span-2 py-5 flex justify-end items-center">
                    <CreateBusiness />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </Popover>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
        {user_city ? (
          <>
            <button
              type="button"
              className="flex items-center gap-3"
              onClick={openLocationModal}
            >
              <img
                src={Location}
                alt="arrow-down"
                className="w-5 h-5 sm:w-7 sm:h-7"
              />
              <p className=" font-normal text-gray-700 text-sm sm:text-lg">
                Select Your Current Location:
              </p>
              <p className="text-primary capitalize underline">{user_city}</p>
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              className="flex items-center gap-4 border border-primary rounded-lg h-[68px] px-5"
              onClick={openLocationModal}
            >
              <img
                src={Location}
                alt="arrow-down"
                className="w-5 h-5 sm:w-7 sm:h-7"
              />
              <p className="text-primary font-normal hover:text-gray-700 text-sm sm:text-lg">
                Select Your Current Location
              </p>
              <img src={ARROW} alt="arrow-down" className="ml-2 w-3 h-2 " />
            </button>
          </>
        )}
        <SelectLocation isOpen={openLocation} setIsOpen={setOpenLocation} />
        <button type="button" className="mt-3 underline" onClick={getLocation}>
          Share Location{" "}
        </button>
      </div>
    </div>
  );
}
